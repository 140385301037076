import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import axios from "axios";
import css from "../quotes-panel/style.module.scss";
import { Container } from "../";

import { OfferCardList, QuotesPanelProfile, SearchFilters } from "../quotes-panel/components/";

import { connect } from "react-redux";
import ContactForm from "../quotes-panel/components/contact-form";
import { uuid4 } from "@sentry/utils";


const QuotesPanelRaw = (props) => {
    const siteData = props.data;

    let getParams: any = () => {
        try {
            return document.location.search
                .substring(1)
                .split("&")
                .reduce((old, current) => {
                    const [key, value] = current.split("=");
                    value && (old[key] = value.replace("%20", " "));
                    return old;
                }, {});
        } catch (e) {
            return {};
        }
    };

    const [results, setResults]: any[] = useState();
    const [state] = useState({
        error: false,
        lastParams: null,
        loading: true,
        lastResults: null,
        params: getParams(),
    });
    const [currentState, setCurrentState] = useState(1);
    const [selectedItems, updateSelectedItems] = useState(new Set());
    const [selectedAgent, setSelectedAgent] = useState();

    const fetchQuotes = async (attributes) => {
        
        let data: any = {
            quote_id: attributes.quote_id,
            plan: {
                product_source: attributes.product_source,
                plan_id: attributes.plan_id,
                ...attributes.product_source == "NATIONAL_GENERAL" ? {
                    has_prior_coverage: attributes.has_prior_coverage,
                    rate_tier: attributes.rate_tier,
                    insurance_network: attributes.insurance_network,
                    plan_key: attributes.plan_key
                } : {}
            },

        }

            console.log("params", attributes )

        try {
            const response = await axios({
                method: "post",
                url: `${props.baseUrl}/v1/quote/selected-plan/`,
                data,

                headers: {
                    "X-Apollo-Quotes-Source": props.data.site.siteMetadata.agent.id,
                },
            });

            const result: any = { plans: {
                NATIONAL_GENERAL: [],
                MARKETPLACE: [],
                ASPIRE_DENTAL: [],
                UNIVERSAL: []
            }};
            const id = uuid4();
            response.data.plan.id = id;

            result.id = attributes.quote_id;


            if (attributes.product_source == "NATIONAL_GENERAL") {
                response.data.plan.premium_options = {
                    lowest_premium: response.data.plan.premium.value,
                    lowest_plan_only_premium: response.data.plan.premium.value,
                    options: [{
                        plan_only_premium: response.data.plan.plan_only_premium.value,
                        premium: response.data.plan.premium.value,
                        has_prior_coverage: attributes.has_prior_coverage,
                        rate_tier: attributes.rate_tier
                    },
                        {
                            plan_only_premium: response.data.plan.plan_only_premium.value,
                            premium: response.data.plan.premium.value,
                            has_prior_coverage: attributes.has_prior_coverage,
                            rate_tier: attributes.rate_tier
                        }
                        , {
                            plan_only_premium: response.data.plan.plan_only_premium.value,
                            premium: response.data.plan.premium.value,
                            has_prior_coverage: attributes.has_prior_coverage,
                            rate_tier: attributes.rate_tier
                        }
                    ]
                }

                props.updateSpecialRates({
                    priorCoverage: attributes.priorCoverage == "true",
                    prefferedRate: attributes.rate_tier == "Preferred",
                });
            }


            result.plans.ALL = [response.data.plan];
            result.plans[attributes.product_source] = [response.data.plan];




            return result;
        } catch (error) {
            console.log("error", error);

            return { 
                error: true 
            };
        }
    };

    try {
        window.onpopstate = (window as any).onpushstate = (e) => {
            setCurrentState(1)

            window.onpopstate = (e2) => {
                if (e2.state == "checkout") {
                    setCurrentState(2)
                    window.history.forward()
                } else {


                    window.location.replace("/");
                }
            }
        };
    } catch (e) { }

    useEffect(() => {
        setSelectedAgent(localStorage.getItem("agent"))
        const params = document.location.search
            .substring(1)
            .split("&")
            .reduce((old, current) => {
                const [key, value] = current.split("=");
                value && (old[key] = value.replace("%20", " "));
                return old;
            }, {});

        !results &&
            fetchQuotes(params).then((data: any) => {
                if (data && data.error) {
                    return;
                }
                if (!_.isEqual(data, results)) {
                    setResults(data);
                    updateSelectedItems(new Set([data.plans.ALL[0].id]))
                }
            });
    }, [state]);


    const universalEnrollment = async ({ firstName, lastName, email, phone, agentFirstName, agentLastName, agentPhone, agentEmail, brokerId }) => {
        const params: any = document.location.search
            .substring(1)
            .split("%7B")
            .join("{")
            .split("%7D")
            .join("}")
            .split("%22")
            .join('"')
            .split("&")
            .reduce((old, current) => {
                const [key, value] = current.split("=");
                value && (old[key] = value.replace("%20", " "));
                return old;
            }, {});


        const plans = JSON.stringify(
            results.plans["UNIVERSAL"].filter((f) =>
                selectedItems.has(f.id)
            )
        );


        localStorage.setItem("universal_plans", plans);
        localStorage.setItem(
            "universal_params",
            JSON.stringify({
                ...params,
                dependents: params.dependents ? JSON.stringify(params.dependents) : [],
                spouse: params.spouse ? JSON.stringify(params.spouse) : undefined,
                email,
                firstName,
                lastName,
                phone,
                quote_id: results.id,
                universal_plans: plans,
                agentFirstName,
                agentLastName,
                agentPhone,
                agentEmail,
                brokerId
            })
        );

        window.open("/enrollment/universal", "_blank");
    };


    const finishEnrollment = async (firstName, lastName, email, phone, npn) => {
        const params: any = document.location.search
            .substring(1)
            .split("%7B")
            .join("{")
            .split("%7D")
            .join("}")
            .split("%22")
            .join('"')
            .split("&")
            .reduce((old, current) => {
                const [key, value] = current.split("=");
                value && (old[key] = value.replace("%20", " "));
                return old;
            }, {});

        const data = {
            firstName: firstName || "t ",
            lastName: lastName || "t ",
            email: email || "t ",
            homePhone: phone || "t",
            gender: params.gender || "t ",
            zip: params.zip_code || "t ",
            custom_Income: params.income ,
            birthDate: params.age ,
        };

        const bodyFormData = new FormData();

        const plans = JSON.stringify(
            results.plans["NATIONAL_GENERAL"].filter((f) =>
                selectedItems.has(f.id)
            )
        );

        const optionalAssociationFeeEnabled = results.plans["NATIONAL_GENERAL"].filter((f) =>
            selectedItems.has(f.id)
        ).filter(f => f?.fees?.MONTHLY?.fees_details[0]?.optional == true).length > 0 ? !!props.associationFee : undefined

        Object.keys(data).forEach((k) => {
            bodyFormData.append(k, data[k]);
        });
        localStorage.setItem("plans", JSON.stringify([]));
        localStorage.setItem(
            "params",
            JSON.stringify({
                ...params,
                specialRates: props.specialRates,
                dependents: params.dependents ? JSON.stringify(params.dependents) : [],
                spouse: params.spouse ? JSON.stringify(params.spouse) : undefined,
                email,
                firstName,
                lastName,
                phone,
                quote_id: results.id,
                optionalAssociationFeeEnabled,
                plan_ids: JSON.stringify(
                    results.plans["NATIONAL_GENERAL"]
                        .filter((f) => selectedItems.has(f.id))
                        .map((p) => p.plan_id.value)
                ),
                plans,
                plan_keys: JSON.stringify(
                    results.plans["NATIONAL_GENERAL"]
                        .filter((f) => selectedItems.has(f.id))
                        .map((p) => p.plan_id.value)
                ),
                npn,
            })
        );

        window.open("/enrollment/national-general", "_blank");
    };

    if(!results) {
        return <div></div>
    }

    const redirectToHealthSherpa = (plan, agentId) => {
        try {
            // const zip_code = 64086;
            // const fips_code = 29095;
            // const s = "MO"

            const params = getParams();
            const spouse = params.spouse
                ? JSON.parse(decodeURIComponent(params.spouse))
                : null;
            const dependents = params.dependents
                ? JSON.parse(decodeURIComponent(params.dependents))
                : [];

            const zip_code = params.zip_code;
            const fips_code = params.fips_code;
            const s = params.state;
            const subsidy_eligible = params.subsidy_eligible;
            const iframe = document.createElement("iframe");
            iframe.src =
                "https://healthsherpa.com/?_agent_id=" +
                (agentId ? agentId : plan.health_sherpa_agent_key.value);
            iframe.width = "0px";
            iframe.height = "0px";
            iframe.style.position = "fixed";
            document.body.appendChild(iframe);

            const spouseStr = spouse
                ? `&applicants[][relationship]=spouse&applicants[][expanded]=true` +
                `&applicants[][age]=${spouse.age}` +
                `&applicants[][smoker]=${spouse.uses_tobacco}` +
                `&applicants[][gender]=${spouse.gender.toLowerCase()}`
                : "";

            const dependentsStr = dependents
                .map((d) => {
                    return (
                        `&applicants[][relationship]=dependent&applicants[][expanded]=true` +
                        `&applicants[][age]=${d.age}` +
                        `&applicants[][smoker]=${d.uses_tobacco}` +
                        `&applicants[][gender]=${d.gender.toLowerCase()}`
                    );
                })
                .join("&");

            setTimeout(() => {
                window.open(
                    `https://www.healthsherpa.com/marketplace/plan/${plan.plan_id.value}?` +
                    `agent_id=${agentId ? agentId : plan.health_sherpa_agent_key.value}&zip_code=${zip_code}` +
                    `&fip_code=${fips_code}` +
                    `&state=${s}` +
                    `&dependents_count=0` +
                    `&household_size=${params.household_size || 1 + (spouse ? 1 : 0) + dependents.length
                    }` +
                    `&apply_for_subsidy=${true}` +
                    `&subsidy=${plan.subsidy.value}` +
                    `&health_subsidy_used=${plan.subsidy.value} ` +
                    `&year=2021` +
                    `&all_benefits=true` +
                    `&add_attributes=true` +
                    `&shopping_scenario=finding_plan` +
                    `&v2=true` +
                    `&health_params[sort]=premium_asc` +
                    `&health_params[premium]=` +
                    `&health_params[deductible]=` +
                    `&health_params[hsa]=false` +
                    `&show_dental=true` +
                    `&applicants[][age]=${params.age}` +
                    `&applicants[][smoker]=${params.uses_tobacco}` +
                    `&applicants[][relationship]=primary` +
                    `&applicants[][gender]=${params.gender.toLowerCase()}` +
                    spouseStr +
                    dependentsStr +
                    `&household_income=${params.income}` +
                    `&csr_type=none` +
                    `&premium=` +
                    `&deductible=`
                    + `&_agent_id=${agentId ? agentId : plan.health_sherpa_agent_key.value}`, "_blank"
                );
            }, 500);
        } catch (ex) { }
    };

  
    return (
        <>
            <Container style={{ marginTop: "60px"}} className={css.mainSearchContainer}>


                <Container className={css.profileContainer}>
                    <Container className={css.quotesFormContainer}>

                        <ContactForm
                            selectedAgent={selectedAgent}
                            setSelectedAgent={setSelectedAgent}
                            redirectToHealthSherpa={redirectToHealthSherpa}
                            finishEnrollment={finishEnrollment}
                            universalEnrollment={universalEnrollment}
                            results={results}
                            selectedItems={selectedItems}
                            specialRates={props.specialRates}
                            goBack={() => {
                               window.location.assign("/");
                            }}
                        />
                    </Container>
                </Container>
            

                <QuotesPanelProfile
                    currentState={currentState}
                    selectedAgent={selectedAgent}
                    setSelectedAgent={setSelectedAgent}
                    quote_id={results?.id}
                    enabled={results}
                    data={siteData}
                />
            </Container>
            <Container id="pdf" />
        </>
    );
};

const mapStateToProps = ({ associationFee, baseUrl, filters, itemsForComparison, results, sort, specialRates }) => {
    return {
        baseUrl,
        associationFee,
        itemsForComparison,
        filters: { ...filters },
        results: { ...results },
        sort: { ...sort },
        specialRates,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchQuotes: (payload) => dispatch({ type: `FETCH_QUOTES`, payload }),
        setApplicants: (payload) =>
            dispatch({ type: `SET_APPLICANTS`, payload }),
        updateSpecialRates: (payload) =>
            dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
        updateAssociationFee: (payload) =>
            dispatch({ type: `UPDATE_ASSOCIATION_FEE`, payload }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesPanelRaw);

export { OfferCardList, SearchFilters };
